import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';

// CONFIG FOR PRODUCTION
// const config = {
//   apiKey: 'AIzaSyCVRUYoWH5b3K6Jt9QGq_jFL81WkIiDWAM',
//   authDomain: 'mbe-qbank.firebaseapp.com',
//   databaseURL: 'https://mbe-qbank.firebaseio.com',
//   projectId: 'mbe-qbank',
//   storageBucket: 'mbe-qbank.appspot.com',
//   messagingSenderId: '560557331651',
//   appId: '1:560557331651:web:a8d880539e6766e3',
// };
// CONFIG FOR STAGE
var config = {
  apiKey: 'AIzaSyDXfNeX9vk7_w-lXzFvMHCAyxElgXfOa0Q',
  authDomain: 'mbe-book.firebaseapp.com',
  databaseURL: 'https://mbe-book.firebaseio.com',
  projectId: 'mbe-book',
  storageBucket: 'mbe-book.appspot.com',
  messagingSenderId: '553676542020',
};
const firebaseStudent = firebase.initializeApp(config, 'firebaseStudent');
export default firebaseStudent;
